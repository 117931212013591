import {createRouter, createWebHistory} from 'vue-router';

import mainRoutes from "./mainRoutes.js";
import authRoutes from "./authRoutes.js";

import { useAuthStore } from "../stores/auth.js";

export const Router = createRouter({
    history: createWebHistory('/admin'),
    routes: [
        {
            path: '/:pathMatch(.*)*',
            component: () => import('../views/authentication/Error.vue')
        },
        mainRoutes,
        authRoutes
    ]
});

Router.beforeEach(async (to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/auth/login'];
    const authRequired = !publicPages.includes(to.path);
    const auth = useAuthStore();

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (authRequired && !auth.user) {
            auth.returnUrl = to.fullPath;
            return next('/auth/login');
        } else next();
    } else {
        next();
    }
});
