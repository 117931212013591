import './bootstrap';

import { createApp } from 'vue'
import {createPinia} from "pinia";
import { createI18n } from 'vue-i18n';
import VueTablerIcons from 'vue-tabler-icons';
import ToastPlugin from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-default.css';

import App from './App.vue';
import {Router} from './router/index.js';
import vuetify from "./plugins/vuetify.js";
import messages from '@/js/utils/locales/messages.js';
import '../scss/style.scss';

const i18n = createI18n({
    locale: 'en',
    legacy: false,
    messages: messages,
    silentTranslationWarn: true,
    silentFallbackWarn: true,
    fallbackWarn: false,
    missingWarn: false
});

const app = createApp(App);

app.use(createPinia());
app.use(Router)
app.use(vuetify)
app.use(i18n);
app.use(VueTablerIcons);
app.use(ToastPlugin);

app.mount('#app');
