const mainRoutes = {
    path: '/',
    meta: {
        requiresAuth: true,
    },
    redirect: '/',
    component: () => import('../layouts/full/FullLayout.vue'),
    children: [
        {
            name: "home",
            path: '/',
            component: () => import('../views/HomePage.vue')
        },
        {
            name: "products",
            path: 'products',
            component: () => import('../views/Product/ProductsList.vue')
        },
        {
            name: 'productDetail',
            path: 'product/:id?',
            component: () => import('../views/Product/ProductDetail.vue')
        },
        {
            name: 'colors',
            path: 'colors',
            component: () => import('../views/Color/ColorList.vue')
        },
        {
            name: 'colorDetail',
            path: 'color/:id?',
            component: () => import('../views/Color/ColorDetail.vue')
        },
        {
            name: 'washingInstructions',
            path: 'washing-instructions',
            component: () => import('../views/WashingInstruction/WashingInstructionList.vue')
        },
        {
            name: 'washingInstructionDetail',
            path: 'washing-instruction/:id?',
            component: () => import('../views/WashingInstruction/WashingInstructionDetail.vue')
        },
        {
            name: 'propertyTypes',
            path: 'specification-types',
            component: () => import('../views/Property/PropertyTypeList.vue')
        },
        {
            name: 'propertyTypesOrder',
            path: 'specification-types/order',
            component: () => import('../views/Property/PropertyTypeOrder.vue')
        },
        {
            name: 'propertyTypeDetail',
            path: 'specification-type/:id?',
            component: () => import('../views/Property/PropertyTypeDetail.vue')
        },
        {
            name: "categories",
            path: "categories",
            component: () => import('../views/Category/CategoryList.vue')
        },
        {
            name: "categoryDetail",
            path: "category/:id?",
            component: () => import('../views/Category/CategoryDetail.vue')
        },
        {
            name: "collections",
            path: "collections",
            component: () => import('../views/Collection/CollectionList.vue')
        },
        {
            name: "collectionDetail",
            path: "collection/:id?",
            component: () => import('../views/Collection/CollectionDetail.vue')
        },
        {
            name: "compositions",
            path: "compositions",
            component: () => import('../views/Composition/CompositionList.vue')
        },
        {
            name: "compositionDetail",
            path: "composition/:id?",
            component: () => import('../views/Composition/CompositionDetail.vue')
        }
    ]
}

export default mainRoutes;
