const authRoutes = {
    path: '/auth',
    meta: {
        requiresAuth: false
    },
    component: () => import('../layouts/blank/BlankLayout.vue'),
    children: [
        {
            name: "Side Login",
            path: "/auth/login",
            component: () => import('../views/authentication/SideLogin.vue'),
        },
        {
            name: "Error",
            path: "/auth/404",
            component: () => import('../views/authentication/Error.vue'),
        }
    ]
}

export default authRoutes;
